import { useTranslation } from 'react-i18next';

import { Images } from '../../assets';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';

export const Subscription: React.FC = () => {
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="s"
      gap="xxl"
    >
      <Text fontSize="xxl">{t('subscription.page.title')}</Text>

      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        gap="xl"
      >
        <Text fontSize="xl" align="center">
          {`${t('subscription.status')} ${t(`common.${loggedInUser?.kind.toLowerCase()}`)}`}
        </Text>

        <Text fontSize="l" style={{ whiteSpace: 'pre-line' }}>
          {t('subscription.info')}
        </Text>

        <Text fontSize="l" style={{ whiteSpace: 'pre-line' }}>
          {t('subscription.info.contact')}

          <span style={{ direction: 'ltr', display: 'inline-block' }}>
            +90 533 558 90 40 - sales@atiraspace.com
          </span>
        </Text>
      </Flex>

      <Flex maxWidth={'15rem'}>
        <AtiraImage src={Images.AtiraSpaceLogoBetaMain} />
      </Flex>
    </Flex>
  );
};
