import { CollapseProps } from 'antd';
import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../../ThemeContext';
import { AtiraCollapse } from '../../../../components/AtiraCollapse';
import { AtiraEmpty } from '../../../../components/AtiraEmpty';
import { Drawer } from '../../../../components/Drawer';
import { ContactTask } from '../../../../model/task/ContactTask';
import { ContactTaskDetails } from './ContactTaskDetails';

interface ContactTasksReadDrawerProps {
  tasks: ContactTask[] | undefined;
  isOpen: boolean;
  onClose: VoidFunction;
}

export const ContactTasksReadDrawer: React.FC<ContactTasksReadDrawerProps> = ({
  tasks,
  isOpen = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const { theme } = useTheme();

  const items: CollapseProps['items'] = useMemo(() => {
    const panelStyle: CSSProperties = {
      borderBottom: '1px solid black',
      backgroundColor: theme.sub,
      border: '0',
    };

    if (tasks?.length) {
      return tasks.map((task, index) => ({
        key: `${index + 1}`,
        label: task?.name,
        children: task ? <ContactTaskDetails contactTask={task} /> : null,
        style: panelStyle,
      }));
    }

    return [];
  }, [tasks, theme.sub]);

  if (!tasks) {
    return null;
  }

  return (
    <Drawer
      title={t('tasks.read.drawer.title')}
      open={isOpen}
      onClose={onClose}
      width={'30rem'}
    >
      {items.length ? (
        <AtiraCollapse items={items} accordion />
      ) : (
        <AtiraEmpty description={t('entries.contact_tasks.empty')} />
      )}
    </Drawer>
  );
};
