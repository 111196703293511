import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import Dropdown from 'antd/es/dropdown/dropdown';
import { MenuProps } from 'antd/es/menu/menu';
import React from 'react';

import i18n, { AppLangs } from '../i18n';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { Text } from './Text';

const currentLanguage = i18n.language.toUpperCase();

const changeLanguage = (lang: AppLangs) => i18n.changeLanguage(lang);

const items: MenuProps['items'] = [
  {
    key: 'g1',
    type: 'group',
    children: [
      {
        key: 'en',
        label: <Text>{AppLangs.EN.toUpperCase()} 🇺🇸</Text>,
        onClick: () => changeLanguage(AppLangs.EN),
      },
      {
        key: 'tr',
        label: <Text>{AppLangs.TR.toUpperCase()} 🇹🇷</Text>,
        onClick: () => changeLanguage(AppLangs.TR),
      },
      {
        key: 'ar',
        label: <Text>{AppLangs.AR.toUpperCase()} 🇸🇦</Text>,
        onClick: () => changeLanguage(AppLangs.AR),
      },
    ],
  },
];

export const LanguageSwitcher: React.FC = () => {
  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      arrow={{ pointAtCenter: true }}
      placement="bottom"
    >
      <Button padding="0" margin="0">
        <Flex alignItems="center" gap="s">
          <AtiraIcon icon={faGlobe} size="2x" color="sub" />
          <Text color="white">
            {currentLanguage.toLowerCase() === AppLangs.AR
              ? 'ع'
              : currentLanguage}
          </Text>
        </Flex>
      </Button>
    </Dropdown>
  );
};
