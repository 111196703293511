import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { PaginationProps, TableProps } from 'antd';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { WarningModal } from '../../components/WarningModal';
import { Entry } from '../../model/entry/Entry';
import { GetLatestEntriesFilterDto } from '../../model/entry/dto/GetLatestEntriesFilterDto';
import { SearchEntryDto } from '../../model/entry/dto/SearchEntryDto';
import { EntryKind } from '../../model/entry/types/EntryKind.enum';
import { PageMeta } from '../../model/meta/PageMeta';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';
import { dashboardSliceSelectors } from '../../redux/dashboard/dashboard.selector';
import { dashboardActions } from '../../redux/dashboard/dashboard.slice';
import { entrySliceSelectors } from '../../redux/entry/entry.selector';
import { entryActions } from '../../redux/entry/entry.slice';
import { formSliceSelectors } from '../../redux/forms/form.selector';
import { formActions } from '../../redux/forms/form.slice';
import { kanbanActions } from '../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { KanbanCardCreateDrawer } from '../kanban/components/KanbanCardCreateDrawer';
import { EntryConvertModal } from './components/EntryConvertModal';
import { EntryCreateModal } from './components/EntryCreateModal';
import { EntryDeleteModal } from './components/EntryDeleteModal';
import { EntryDetailsDrawer } from './components/EntryDetailsDrawer';
import { EntryTableBulkActionsHeader } from './components/EntryTableBulkActionsHeader';
import { EntryTableHeader } from './components/EntryTableHeader';
import { EntryUpdateDrawer } from './components/EntryUpdateDrawer';
import { ContactTaskCreateDrawer } from './components/contact-tasks/ContactTaskCreateDrawer';
import { ContactTaskUpdateDrawer } from './components/contact-tasks/ContactTaskUpdateDrawer';
import { ContactTasksReadDrawer } from './components/contact-tasks/ContactTasksReadDrawer';
import { LeadTaskCreateDrawer } from './components/lead-tasks/LeadTaskCreateDrawer';
import { LeadTaskUpdateDrawer } from './components/lead-tasks/LeadTaskUpdateDrawer';
import { LeadTasksReadDrawer } from './components/lead-tasks/LeadTasksReadDrawer';
import { EntiresTableWithDND } from './components/table/EntriesTableWithDND';
import { useEntriesContext } from './entries-context';
import { createEntriesTableColumns } from './utils/createEntriesTableColumns';

const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 1rem;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const StyledContactTasksReadDrawer = styled(ContactTasksReadDrawer)`
  .ant-drawer-mask {
    z-index: 999 !important;
  }
`;

const StyledLeadTasksReadDrawer = styled(LeadTasksReadDrawer)`
  .ant-drawer-mask {
    z-index: 999 !important;
  }
`;

const PAGE_SIZE = 10;

export const EntriesRoute: React.FC = () => {
  const formMethods = useForm<GetLatestEntriesFilterDto | SearchEntryDto>({
    defaultValues: {
      formId: undefined,
      kind: undefined,
      range: undefined,
      keyword: '',
    },
  });
  const keyword = formMethods.watch('keyword');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const [entryDeleteLoading, setEntryDeleteLoading] = useState(false);
  const [entryDeleteModalVisible, setEntryDeleteModalVisible] = useState(false);
  const [entryConvertModalVisible, setEntryConvertModalVisible] =
    useState(false);
  const [entryDetailsDrawerVisible, setEntryDetailsDrawerVisible] =
    useState(false);
  const [entryCreateModalVisible, setEntryCreateModalVisible] = useState(false);
  const [kanbanCardCreateDrawerVisible, setKanbanCardCreateDrawerVisible] =
    useState(false);
  const [entryUpdateDrawerVisible, setEntryUpdateDrawerVisible] =
    useState(false);
  const [contactTaskCreateDrawerVisible, setContactTaskCreateDrawerVisible] =
    useState(false);
  const [deleteTaskLoading, setDeleteTaskLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [newEntriesLoading, setNewEntriesLoading] = useState(false);

  const {
    entry: currentEntry,
    setEntry,
    task,
    contactTaskUpdateDrawerVisible,
    setContactTaskUpdateDrawerVisible,
    contactTasksReadDrawerVisible,
    setContactTasksReadDrawerVisible,
    leadTasksReadDrawerVisibile,
    setLeadTasksReadDrawerVisible,
    leadTaskUpdateDrawerVisibile,
    setLeadTaskUpdateDrawerVisible,
    leadTaskCreateDrawerVisible,
    setLeadTaskCreateDrawerVisible,
    deleteTaskWarningModalVisible,
    setDeleteTaskWarningModalVisible,
  } = useEntriesContext();

  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const forms = useAppSelector(formSliceSelectors.selectMyForms);
  const entries = useAppSelector(entrySliceSelectors.selectLatestEntries);
  const searchEntries = useAppSelector(entrySliceSelectors.selectSearchEntries);
  const searchEntriesLoading = useAppSelector(
    entrySliceSelectors.selectSearchEntriesLoading,
  );
  const entriesLoading = useAppSelector(
    entrySliceSelectors.selectLatestEntriesLoading,
  );
  const entriesMeta = useAppSelector(
    entrySliceSelectors.selectLatestEntriesMeta,
  );
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  );
  const totalEntries = useAppSelector(
    dashboardSliceSelectors.selectDashboardStats,
  )?.entries.total;

  const deleteEntryWarning = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setEntryDeleteModalVisible(true);
    },
    [setEntry],
  );

  const convertEntryWarning = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setEntryConvertModalVisible(true);
    },
    [setEntry],
  );

  const onShowEntryDetails = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setEntryDetailsDrawerVisible(true);
    },
    [setEntry],
  );

  const onCreateDeal = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setKanbanCardCreateDrawerVisible(true);
    },
    [setEntry],
  );

  const updateEntry = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setEntryUpdateDrawerVisible(true);
    },
    [setEntry],
  );

  const createContactTask = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setContactTaskCreateDrawerVisible(true);
    },
    [setEntry],
  );

  const createLeadTask = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setLeadTaskCreateDrawerVisible(true);
    },
    [setEntry, setLeadTaskCreateDrawerVisible],
  );

  const readLeadTasks = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setLeadTasksReadDrawerVisible(true);
      dispatch(entryActions.getEntryById({ entryId: entry._id, userId }));
    },
    [dispatch, setEntry, setLeadTasksReadDrawerVisible, userId],
  );

  const readContactTasks = useCallback(
    (entry: Entry) => {
      dispatch(entryActions.getEntryById({ entryId: entry._id, userId }));
      setEntry(entry);
      setContactTasksReadDrawerVisible(true);
    },
    [dispatch, setContactTasksReadDrawerVisible, setEntry, userId],
  );

  const deleteEntry = async () => {
    try {
      setEntryDeleteLoading(true);
      await dispatch(
        entryActions.deleteEntry({
          userId: userId,
          entryId: currentEntry?._id!,
        }),
      ).unwrap();
      setEntryDeleteModalVisible(false);
      await dispatch(
        entryActions.getLatestEntries({
          userId,
          meta: { count: PAGE_SIZE, page: 0 },
        }),
      ).unwrap();
      AtiraToast.success(t('entries.delete.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setEntryDeleteLoading(false);
    }
  };

  const deleteTask = async () => {
    try {
      setDeleteTaskLoading(true);

      currentEntry?.kind === EntryKind.LEAD
        ? await dispatch(
            entryActions.deleteLeadTask({ taskId: task?._id!, userId }),
          ).unwrap()
        : await dispatch(
            entryActions.deleteContactTask({ taskId: task?._id!, userId }),
          ).unwrap();

      const updatedEntry = await dispatch(
        entryActions.getEntryById({ entryId: currentEntry?._id!, userId }),
      ).unwrap();

      setEntry(updatedEntry);

      setDeleteTaskWarningModalVisible(false);

      AtiraToast.success(t('tasks.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setDeleteTaskLoading(false);
    }
  };

  const onPageChange: TableProps['onChange'] = async (pagination) => {
    try {
      const canFetchMore =
        entriesMeta?.hasNext &&
        (pagination.current || 0) - entriesMeta.page === 1;

      if (canFetchMore) {
        setNewEntriesLoading(true);
        await dispatch(
          entryActions.getLatestEntries({
            userId,
            meta: { count: entriesMeta.count, page: entriesMeta.page },
            ...omitBy(formMethods.getValues(), isNil),
          }),
        ).unwrap();
      }
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setNewEntriesLoading(false);
    }
  };

  const onCloseContactTaskUpdateReadDrawer = () => {
    setContactTaskUpdateDrawerVisible(false);
    setContactTasksReadDrawerVisible(true);
  };

  const onCloseLeadTaskUpdateReadDrawer = async () => {
    try {
      const entry = await dispatch(
        entryActions.getEntryById({ entryId: task?.entryId!, userId }),
      ).unwrap();
      setEntry(entry);
      setLeadTaskUpdateDrawerVisible(false);
      setLeadTasksReadDrawerVisible(true);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    }
  };

  const tableColumns = createEntriesTableColumns(tableSettings?.columns || [], {
    delete: deleteEntryWarning,
    convert: convertEntryWarning,
    details: onShowEntryDetails,
    deal: onCreateDeal,
    createContactTask,
    createLeadTask,
    readContactTasks,
    readLeadTasks,
    t,
    update: updateEntry,
  });

  const getPaginationItems: PaginationProps['itemRender'] = (page, type) => {
    switch (type) {
      case 'prev':
        return <Button padding="0.3rem 0.6rem" margin="0.5rem" title={'<'} />;
      case 'next':
        return <Button padding="0.3rem 0.6rem" margin="0.5rem" title={'>'} />;
      case 'page':
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(formActions.getMyForms({ userId }));
    dispatch(dashboardActions.getDashboardStats({ userId }));
    dispatch(entryActions.getUserTableSettings({ userId }));
    dispatch(kanbanActions.getUserDefaultKanban({ userId }));
  }, [userId, dispatch]);

  useEffect(() => {
    if (state?.useFilter) {
      const form = state.useFilter;
      formMethods.setValue('formId', form?._id);
    }

    dispatch(
      entryActions.getLatestEntries({
        userId,
        ...omitBy(formMethods.getValues(), isNil),
        meta: PageMeta.create({ count: PAGE_SIZE, page: 0 }),
      }),
    );
  }, [dispatch, formMethods, state, userId]);

  return (
    <FormProvider {...formMethods}>
      <Flex flexDirection="column" height={'100%'}>
        <SubHeader
          title={t('common.entries')}
          icon={faPlus}
          onClick={() => setEntryCreateModalVisible(true)}
          buttonTitle={t('common.create')}
        />

        <Wrapper>
          {selectedRows.length ? (
            <EntryTableBulkActionsHeader
              setSelectedEntriesIds={setSelectedRows}
              selctedEntriesIds={selectedRows}
            />
          ) : (
            <EntryTableHeader />
          )}

          <EntiresTableWithDND
            key={JSON.stringify(tableColumns)}
            columns={tableColumns}
            data={keyword ? searchEntries : entries}
            pagination={{
              showSizeChanger: false,
              pageSize: PAGE_SIZE,
              total: totalEntries,
              hideOnSinglePage: true,
              itemRender: getPaginationItems,
              // FIXME once there's a filter, current is stuck
              // Maybe, change total to filtered results size
            }}
            loading={
              (entriesLoading && !entries.length) ||
              newEntriesLoading ||
              searchEntriesLoading
            }
            selectedRowKeys={selectedRows}
            onSelectedRowKeysChange={setSelectedRows}
            onChange={onPageChange}
          />

          <EntryDeleteModal
            isOpen={entryDeleteModalVisible}
            onConfirm={deleteEntry}
            loading={entryDeleteLoading}
            onClose={() => setEntryDeleteModalVisible(false)}
          />

          <EntryConvertModal
            key={currentEntry?._id}
            isOpen={entryConvertModalVisible}
            onClose={() => setEntryConvertModalVisible(false)}
            entry={currentEntry}
          />

          <EntryCreateModal
            isOpen={entryCreateModalVisible}
            onClose={() => setEntryCreateModalVisible(false)}
            showSelect={forms.length > 0}
            key={currentForm?._id}
          />

          <EntryDetailsDrawer
            key={currentEntry?._id}
            isOpen={entryDetailsDrawerVisible}
            onClose={() => setEntryDetailsDrawerVisible(false)}
            entry={currentEntry}
          />

          <EntryUpdateDrawer
            isOpen={entryUpdateDrawerVisible}
            entry={currentEntry}
            onClose={() => setEntryUpdateDrawerVisible(false)}
          />

          {currentEntry?.kind === EntryKind.CONTACT ? (
            <Flex>
              <ContactTaskCreateDrawer
                isOpen={contactTaskCreateDrawerVisible}
                entry={currentEntry}
                onClose={() => setContactTaskCreateDrawerVisible(false)}
              />

              <StyledContactTasksReadDrawer
                isOpen={contactTasksReadDrawerVisible}
                tasks={currentEntry.contactTasks}
                onClose={() => setContactTasksReadDrawerVisible(false)}
              />

              <ContactTaskUpdateDrawer
                contactTask={task as ContactTask}
                isOpen={contactTaskUpdateDrawerVisible}
                onClose={onCloseContactTaskUpdateReadDrawer}
              />

              <KanbanCardCreateDrawer
                entry={currentEntry}
                isOpen={kanbanCardCreateDrawerVisible}
                onClose={() => setKanbanCardCreateDrawerVisible(false)}
              />
            </Flex>
          ) : null}

          {currentEntry?.kind === EntryKind.LEAD ? (
            <Flex>
              <StyledLeadTasksReadDrawer
                tasks={currentEntry.leadTasks}
                isOpen={leadTasksReadDrawerVisibile}
                onClose={() => setLeadTasksReadDrawerVisible(false)}
              />

              <LeadTaskUpdateDrawer
                leadTask={task as LeadTask}
                isOpen={leadTaskUpdateDrawerVisibile}
                onClose={onCloseLeadTaskUpdateReadDrawer}
                onSetReadDrawerVisible={setLeadTasksReadDrawerVisible}
              />

              <LeadTaskCreateDrawer
                entry={currentEntry}
                isOpen={leadTaskCreateDrawerVisible}
                onClose={() => setLeadTaskCreateDrawerVisible(false)}
              />
            </Flex>
          ) : null}

          {currentEntry?.kind === EntryKind.CONTACT ||
          currentEntry?.kind === EntryKind.LEAD ? (
            <WarningModal
              isOpen={deleteTaskWarningModalVisible}
              loading={deleteTaskLoading}
              onConfirm={deleteTask}
              onClose={() => setDeleteTaskWarningModalVisible(false)}
              title={t('tasks.delete.warning.title', { task_name: task?.name })}
              description={t('tasks.delete.warning.description')}
            />
          ) : null}
        </Wrapper>
      </Flex>
    </FormProvider>
  );
};
