import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraTable } from '../../../components/AtiraTable';
import { Flex } from '../../../components/Flex';
import { PageMeta } from '../../../model/meta/PageMeta';
import { TaskKind } from '../../../model/task/types/TaskKind.eum';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { taskSliceSelectors } from '../../../redux/task/task.selector';
import { taskActions } from '../../../redux/task/task.slice';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { createLeadTasksColumns } from '../columns/createDealTasksTableColumns';
import { useTaskContext } from '../task-context';

export const LeadTasksTab: React.FC = () => {
  const leadTasks = useAppSelector(taskSliceSelectors.selectLeadTasks);
  const loading = useAppSelector(taskSliceSelectors.selectLeadTasksLoading);
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    setLeadTasksReadDrawerVisible,
    setTask,
    setLeadTaskUpdateDrawerVisible,
    setDeleteTaskWarningModalVisible,
  } = useTaskContext();

  const columns = useMemo(
    () =>
      createLeadTasksColumns({
        deleteTask: (t) => {
          setTask(t);
          setDeleteTaskWarningModalVisible(true);
        },
        previewTask: (t) => {
          setTask(t);
          setLeadTasksReadDrawerVisible(true);
        },
        updateTask: (t) => {
          setTask(t);
          setLeadTaskUpdateDrawerVisible(true);
        },
        t,
      }),
    [
      setDeleteTaskWarningModalVisible,
      setLeadTaskUpdateDrawerVisible,
      setLeadTasksReadDrawerVisible,
      setTask,
      t,
    ],
  );

  useEffect(() => {
    dispatch(taskActions.getLeadTasks({ userId, meta: PageMeta.create() }));
  }, [dispatch, userId]);

  return (
    <Flex flexDirection="column" width="100%" paddingRight="m" paddingLeft="m">
      <AtiraTable
        data={leadTasks}
        columns={columns}
        loading={loading && !leadTasks.length}
        rowSelection={undefined}
        pagination={{
          pageSize: 10,
          total: leadTasks.length,
          showSizeChanger: false,
        }}
        size="small"
      />
    </Flex>
  );
};
