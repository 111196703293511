import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { useTranslation } from 'react-i18next';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { TasksDrawers } from './components/TasksDrawers';
import { DealTasksTab } from './tabs/DealTasksTab';
import { KanbanTasksTab } from './tabs/KanbanTasksTab';
import { LeadTasksTab } from './tabs/LeadTasksTab';
import { TaskContextProvider } from './task-context';

export const TasksRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <TaskContextProvider>
      <Flex flexDirection="column">
        <SubHeader
          title={t('common.tasks')}
          icon={faPlus}
          buttonTitle={t('common.create')}
          onClick={() => {}}
        />
        <AtiraTabs
          tabs={[
            { tabTitle: t('common.lead_tasks'), tabPanel: <LeadTasksTab /> },
            {
              tabTitle: t('common.contact_tasks'),
              tabPanel: <KanbanTasksTab />,
            },
            { tabTitle: t('common.deal_tasks'), tabPanel: <DealTasksTab /> },
          ]}
        />
        <TasksDrawers />
      </Flex>
    </TaskContextProvider>
  );
};
