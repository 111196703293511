import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { AtriaTourClasses } from '../../../../components/tour/AtiraTourClasses.enum';
import { WidgetKind } from '../../../../model/widget/types/WidgetKid.enum';
import { dashboardSliceSelectors } from '../../../../redux/dashboard/dashboard.selector';
import { dashboardActions } from '../../../../redux/dashboard/dashboard.slice';
import { formSliceSelectors } from '../../../../redux/forms/form.selector';
import { formActions } from '../../../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { widgetSliceSelectors } from '../../../../redux/widget/widget.selector';
import { Breakpoints } from '../../../../theme/Breakpoints';
import { Rounded } from '../../../../theme/Rounded';
import { Shadow } from '../../../../theme/Shadow';
import { Spacing } from '../../../../theme/Spacing';
import { pluralize, truncateString } from '../../../../utils/String';
import { EntryCreateModal } from '../../../entries/components/EntryCreateModal';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  width: 100%;
  min-height: 14rem;
  padding: 1rem;
  box-shadow: ${Shadow.SM};
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  margin: 0;
  bottom: 0;
  right: 0;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: ${Spacing.s};
`;

const Title = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  padding: 0.2rem 0;
`;

const ResponsiveText = styled(Text)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.gray};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    font-size: 1.5rem;
  }
`;

const MainColorSpan = styled.span`
  color: ${({ theme }) => theme.main};
  text-overflow: ellipsis;
`;

const MainColorLink = styled(Link)`
  color: ${({ theme }) => theme.main};

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;

export const WidgetStats: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [entryCreateModalVisible, setEntryCreateModalVisible] = useState(false);

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const stats = useAppSelector(dashboardSliceSelectors.selectDashboardStats);
  const forms = useAppSelector(formSliceSelectors.selectMyForms);
  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);
  const widget = useAppSelector(widgetSliceSelectors.selectUserWidgets)?.find(
    (w) => w.kind === WidgetKind.STATS,
  );

  useEffect(() => {
    dispatch(dashboardActions.getDashboardStats({ userId: user._id }));
    dispatch(formActions.getMyForms({ userId: user._id }));
  }, [dispatch, user._id]);

  return (
    <Wrapper>
      <Flex alignItems="center" justifyContent="space-between">
        <Title fontSize="l">
          {t(`dashboard.widget.title.${widget?.name}`)}
        </Title>
        <StyledButton onClick={() => setEntryCreateModalVisible(true)}>
          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faPlus} color="main" />
            <Text color="main">{t('common.add_new')}</Text>
          </Flex>
        </StyledButton>
      </Flex>

      <Flex>
        <Container>
          <Flex
            flexDirection="column"
            gap="m"
            width={'100%'}
            className={AtriaTourClasses.WIDGET_STATS}
            position="relative"
          >
            <Flex>
              <ResponsiveText fontWeight={'bold'} wordBreak="break-all">
                {`${t('common.welcome')} `}

                <MainColorSpan>
                  <MainColorLink to={'/profile'}>
                    {truncateString(user.name)}
                  </MainColorLink>
                </MainColorSpan>

                {` ${t('common.your_space')}`}
              </ResponsiveText>
            </Flex>

            <Flex flexDirection="column" gap="m" justifyContent="center">
              <Flex gap="s" alignItems="center">
                <AtiraIcon icon={faCircle} color="main" size="xs" />

                <ResponsiveText>
                  <MainColorSpan>{`${stats?.entries.categories.LEAD} `}</MainColorSpan>

                  {stats.entries.categories.LEAD <= 1
                    ? t('common.lead')
                    : t('common.leads')}

                  {` ${t('common.and')} `}

                  <MainColorSpan>
                    {`${stats?.entries.categories.CONTACT} `}
                  </MainColorSpan>

                  {stats.entries.categories.CONTACT <= 1
                    ? t('common.contact')
                    : t('common.contacts')}
                </ResponsiveText>
              </Flex>

              <Flex gap="s" alignItems="center">
                <AtiraIcon icon={faCircle} color="main" size="xs" />

                <ResponsiveText>
                  <MainColorSpan>{`${stats?.forms.total} `}</MainColorSpan>

                  {stats.forms.total <= 1
                    ? t('common.form')
                    : t('common.forms')}

                  {` ${t('common.and')} `}

                  <MainColorSpan>{`${stats?.reports.total} `}</MainColorSpan>

                  {stats.reports.total <= 1
                    ? t('common.report')
                    : t('common.reports')}
                </ResponsiveText>
              </Flex>
            </Flex>

            <EntryCreateModal
              isOpen={entryCreateModalVisible}
              onClose={() => setEntryCreateModalVisible(false)}
              showSelect={forms.length > 0}
              key={currentForm?._id}
            />
          </Flex>
        </Container>
      </Flex>
    </Wrapper>
  );
};
