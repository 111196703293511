import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Images } from '../../assets';
import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Shadow } from '../../theme/Shadow';
import { Spacing } from '../../theme/Spacing';
import { AtiraColumn } from '../entries/components/AtiraColumn';

const TogglePlanWrapper = styled(Flex)`
  border-radius: ${Rounded.pill};
  padding: ${Spacing.s};
  border: ${({ theme }) => `1px solid ${theme.main}`};
  gap: 0.2rem;
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${Shadow.LG};
`;

const TogglePlanButton = styled(Button)<{ isClicked: boolean }>`
  background-color: ${({ theme, isClicked }) =>
    isClicked ? theme.main : theme.transparent};
  font-size: 1.3rem;
  color: ${({ theme, isClicked }) => (isClicked ? theme.white : theme.main)};
  margin: 0;
  border-radius: ${Rounded.pill};
  padding: 0.6rem ${Spacing.m};
  flex-grow: 1;
  user-select: none;
`;

const Card = styled(Flex)`
  border-radius: ${Rounded.xl};
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.m};
  box-shadow: ${Shadow.MD};
  background-color: ${({ theme }) => theme.white};
  padding-bottom: ${Spacing.m};
  height: 100%;

  @media (min-width: ${Breakpoints.TABLET}) {
    margin: 0 ${Spacing.m};
    padding-block: ${Spacing.m};
  }
`;

const CardTitle = styled(Text)`
  font-weight: 700;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.white};
`;

const Price = styled(Text)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.white};
  font-weight: bold;
`;

const FeaturesWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.xl};
  width: 100%;
  flex-grow: 1;
  padding-inline: ${Spacing.xxl};
  margin-top: ${Spacing.xxl};
`;

const Feature = styled(Flex)`
  gap: ${Spacing.l};
  align-items: center;
`;

const SectionCallToAction = styled(Link)`
  text-decoration: none;
  font-size: 1rem;
  padding: ${Spacing.s} ${Spacing.m};
  color: ${({ theme }) => theme.sub};
  background-color: ${({ theme }) => theme.main};
  border-radius: ${Rounded.md};
  transition: all 0.3s;
  width: fit-content;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.sub};
  }
`;

const HeroSection = styled(Flex)`
  background-color: ${({ theme }) => theme.sub};
  padding-inline: ${Spacing.m};
  padding-block: ${Spacing.l};
  flex-wrap: wrap;
  gap: ${Spacing.l};
  justify-content: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: ${Spacing.xxl};
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    padding-inline: calc(${Spacing.xxl}*1.5);
  }
`;

const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  gap: ${Spacing.m};
`;

const HeroTitle = styled(Text)`
  color: ${({ theme }) => theme.main};
  font-size: 1.75rem;
  font-weight: 600;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 2.25rem;
  }
`;

const HeroDescription = styled(Text)`
  font-weight: bold;
  font-size: 1.5rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 2rem;
  }
`;

const HeroParagraph = styled(Text)`
  font-size: 1.25rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.75rem;
  }
`;

const CardPlanInfoWrapper = styled(Flex)<{ isMiddle?: boolean }>`
  border-radius: ${Rounded.xl};
  padding-inline: ${Spacing.l};
  padding-block: ${Spacing.xl};
  flex-direction: column;
  gap: ${Spacing.s};
  align-items: center;
  background-color: ${({ theme, isMiddle }) =>
    isMiddle ? theme.white : theme.main};
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0;
  box-shadow: ${Shadow.MD};

  @media (min-width: ${Breakpoints.TABLET}) {
    width: calc(100% + 2rem);
    margin: 0 -${Spacing.xl};
  }

  ::after {
    content: '';
    position: absolute;
    height: 50px;
    width: 100%;
    z-index: -1;
    background-color: ${({ theme, isMiddle }) =>
      isMiddle ? theme.white : theme.main};
    border-radius: 0 0 ${Rounded.xl} ${Rounded.xl};
    bottom: -17px;
    left: 0;
    transform: skewY(5deg);
  }
`;

const PaidPlansSection = styled(HeroSection)`
  background-color: ${({ theme }) => theme.white};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding-block: ${Spacing.xxl};
  }
`;

const AddOnsSection = styled(PaidPlansSection)``;

const CardCallToActionWrapper = styled(Flex)`
  padding: ${Spacing.m};
  border-top: ${({ theme }) => `1px solid ${theme.sub}`};
  width: 80%;
  justify-content: center;
`;

const CardCallToAction = styled(Link)`
  text-decoration: none;
  border-radius: ${Rounded.md};
  color: ${({ theme }) => theme.sub};
  background-color: ${({ theme }) => theme.main};
  font-size: 1.2rem;
  transition: all 0.3s;
  padding: ${Spacing.s};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.sub};
  }
`;

const AddOnsSectionParagraph = styled(Text)`
  line-height: 2rem;
  font-size: 1.25rem;
`;

enum Plans {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export const Pricing: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<Plans>(Plans.MONTHLY);
  const [opacity, setOpacity] = useState(1);

  const { t } = useTranslation();

  const { theme } = useTheme();

  const handlePlanChange = (plan: Plans) => {
    setOpacity(0);
    setSelectedPlan(plan);

    setTimeout(() => {
      setOpacity(1);
    }, 200);
  };

  return (
    <Container>
      <HeroSection>
        <Row
          justify={'center'}
          align={'middle'}
          style={{ width: '100%', minHeight: '90vh' }}
        >
          <AtiraColumn sm={24} md={12} lg={12} xl={12}>
            <Flex flexDirection="column" gap="l">
              <HeroTitle>{t('pricing.hero.title')}</HeroTitle>

              <HeroDescription>{t('pricing.hero.description')}</HeroDescription>

              <HeroParagraph>
                <span style={{ fontStyle: 'italic', color: theme.main }}>
                  {`${t('pricing.early_bird_discount')} `}
                </span>
                {t('pricing.hero.paragraph')}
              </HeroParagraph>
            </Flex>
          </AtiraColumn>

          <AtiraColumn sm={24} md={12} lg={12} xl={12}>
            <AtiraImage src={Images.PricingHeroImage} />
          </AtiraColumn>

          <AtiraColumn xs={16} sm={10} md={8} lg={7} xl={6}>
            <Flex flexDirection="column" gap="s">
              <Text align="center" fontWeight={'bold'}>
                {t('pricing.hero.discount')}
              </Text>

              <TogglePlanWrapper>
                <TogglePlanButton
                  onClick={() => handlePlanChange(Plans.MONTHLY)}
                  isClicked={selectedPlan === Plans.MONTHLY}
                >
                  {t('common.monthly')}
                </TogglePlanButton>

                <TogglePlanButton
                  onClick={() => handlePlanChange(Plans.YEARLY)}
                  isClicked={selectedPlan === Plans.YEARLY}
                >
                  {t('common.yearly')}
                </TogglePlanButton>
              </TogglePlanWrapper>
            </Flex>
          </AtiraColumn>
        </Row>

        <Row
          justify={'space-around'}
          gutter={[20, 12]}
          style={{ width: '100%', transition: 'all 0.2s', opacity }}
        >
          <AtiraColumn xs={24} sm={12} md={12} lg={8} xl={8}>
            <Card>
              <CardPlanInfoWrapper>
                <CardTitle>{t('common.free')}</CardTitle>

                <Price>
                  <sup style={{ fontSize: '0.75rem' }}>$ </sup>0
                </Price>

                <Text color="white">
                  {t(
                    selectedPlan === Plans.MONTHLY
                      ? 'common.per_month'
                      : 'common.per_year',
                  )}
                </Text>
              </CardPlanInfoWrapper>

              <FeaturesWrapper>
                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">{`1 ${t('common.user')}`}</Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">
                    {t('pricing.planCard.entriesCount', { count: 500 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">
                    {t('pricing.planCard.formsCount', { count: 3 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">{`1 ${t('common.pipeline')}`}</Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">{`3 ${t('common.custom_fields')}`}</Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm" color="gray">
                    {`${t('pricing.hero.plan_card.fearure.app')}`}{' '}
                    <span style={{ fontStyle: 'italic' }}>
                      ({t('common.soon')})
                    </span>
                  </Text>
                </Feature>
              </FeaturesWrapper>

              <CardCallToActionWrapper>
                <CardCallToAction to={''}>
                  {t('common.start_now')}
                </CardCallToAction>
              </CardCallToActionWrapper>
            </Card>
          </AtiraColumn>

          <AtiraColumn xs={24} sm={12} md={12} lg={8} xl={8}>
            <Card backgroundColor={theme.main}>
              <CardPlanInfoWrapper isMiddle>
                <CardTitle style={{ color: theme.main }}>
                  {t('common.basic')}
                </CardTitle>

                <Price style={{ color: theme.main }}>
                  <sup style={{ fontSize: '0.75rem' }}>$ </sup>

                  {t('pricing.early_bird_discount')}
                </Price>

                <Text color="main">
                  {t(
                    selectedPlan === Plans.MONTHLY
                      ? 'common.per_month'
                      : 'common.per_year',
                  )}
                </Text>
              </CardPlanInfoWrapper>

              <FeaturesWrapper>
                <Feature>
                  <AtiraIcon
                    icon={faCircle}
                    color="sub"
                    style={{ fontSize: '0.4rem' }}
                  />

                  <Text fontSize="xm" color="sub">
                    {t('pricing.planCard.usersCount', { count: 3 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon
                    color="sub"
                    icon={faCircle}
                    style={{ fontSize: '0.4rem' }}
                  />

                  <Text fontSize="xm" color="sub">
                    {t('pricing.planCard.entriesCount', { count: 10000 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon
                    color="sub"
                    icon={faCircle}
                    style={{ fontSize: '0.4rem' }}
                  />

                  <Text fontSize="xm" color="sub">
                    {t('pricing.planCard.formsCount', { count: 9 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon
                    color="sub"
                    icon={faCircle}
                    style={{ fontSize: '0.4rem' }}
                  />

                  <Text fontSize="xm" color="sub">
                    {t('pricing.planCard.pipelinesCount', { count: 3 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon
                    color="sub"
                    icon={faCircle}
                    style={{ fontSize: '0.4rem' }}
                  />

                  <Text
                    fontSize="xm"
                    color="sub"
                  >{`10 ${t('common.custom_fields')}`}</Text>
                </Feature>

                <Feature>
                  <AtiraIcon
                    color="sub"
                    icon={faCircle}
                    style={{ fontSize: '0.4rem' }}
                  />

                  <Text fontSize="xm" color="sub">
                    {`${t('pricing.hero.plan_card.fearure.app')}`}{' '}
                    <span style={{ fontStyle: 'italic' }}>
                      ({t('common.soon')})
                    </span>
                  </Text>
                </Feature>
              </FeaturesWrapper>

              <CardCallToActionWrapper>
                <CardCallToAction
                  style={{ border: `1px solid ${theme.sub}` }}
                  to={''}
                >
                  {t('pricing.hero.plan_card.c2a')}
                </CardCallToAction>
              </CardCallToActionWrapper>
            </Card>
          </AtiraColumn>

          <AtiraColumn xs={24} sm={12} md={12} lg={8} xl={8}>
            <Card>
              <CardPlanInfoWrapper>
                <CardTitle>{t('common.premium')}</CardTitle>

                <Price>
                  <sup style={{ fontSize: '0.75rem' }}>$</sup>{' '}
                  {t('pricing.early_bird_discount')}
                </Price>

                <Text color="white">
                  {t(
                    selectedPlan === Plans.MONTHLY
                      ? 'common.per_month'
                      : 'common.per_year',
                  )}
                </Text>
              </CardPlanInfoWrapper>

              <FeaturesWrapper>
                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">
                    {t('pricing.planCard.usersCount', { count: 10 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">
                    {t('pricing.planCard.entriesCount', { count: 50000 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">
                    {t('pricing.planCard.formsCount', { count: 25 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">
                    {t('pricing.planCard.pipelinesCount', { count: 5 })}
                  </Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm">{`25 ${t('common.custom_fields')}`}</Text>
                </Feature>

                <Feature>
                  <AtiraIcon icon={faCircle} style={{ fontSize: '0.4rem' }} />

                  <Text fontSize="xm" color="gray">
                    {`${t('pricing.hero.plan_card.fearure.app')}`}{' '}
                    <span style={{ fontStyle: 'italic' }}>
                      ({t('common.soon')})
                    </span>
                  </Text>
                </Feature>
              </FeaturesWrapper>

              <CardCallToActionWrapper>
                <CardCallToAction to={''}>
                  {t('pricing.hero.plan_card.c2a')}
                </CardCallToAction>
              </CardCallToActionWrapper>
            </Card>
          </AtiraColumn>
        </Row>
      </HeroSection>

      <PaidPlansSection>
        <Row align={'middle'} style={{ width: '100%' }}>
          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <AtiraImage src={Images.PricingPaidPlansImage} />
          </AtiraColumn>

          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <Flex flexDirection="column" gap="l">
              <Text fontSize="xxl" fontWeight={'bold'}>
                {t('pricing.paid_plans.title')}
              </Text>

              <Flex flexDirection="column" gap="l">
                <Flex gap="m" alignItems="center">
                  <AtiraIcon color="main" icon={faCheckSquare} size="xl" />

                  <Text fontSize="xm">{t('pricing.paid_plans.feature.1')}</Text>
                </Flex>

                <Flex gap="m" alignItems="center">
                  <AtiraIcon color="main" icon={faCheckSquare} size="xl" />

                  <Text fontSize="xm">{t('pricing.paid_plans.feature.2')}</Text>
                </Flex>

                <Flex gap="m" alignItems="center">
                  <AtiraIcon color="main" icon={faCheckSquare} size="xl" />

                  <Text fontSize="xm">{t('pricing.paid_plans.feature.3')}</Text>
                </Flex>

                <Flex gap="m" alignItems="center">
                  <AtiraIcon color="main" icon={faCheckSquare} size="xl" />

                  <Text fontSize="xm">{t('pricing.paid_plans.feature.4')}</Text>
                </Flex>
              </Flex>

              <SectionCallToAction to={''}>
                {t('pricing.section_c2a')}
              </SectionCallToAction>
            </Flex>
          </AtiraColumn>
        </Row>
      </PaidPlansSection>

      <AddOnsSection>
        <Row align={'middle'} style={{ width: '100%' }}>
          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <Flex flexDirection="column" gap="l">
              <Text fontSize="xxl" fontWeight={'bold'} color="main">
                {t('pricing.add_ons.title')}
              </Text>

              <AddOnsSectionParagraph>
                {t('pricing.add_ons.paragraph')}
              </AddOnsSectionParagraph>

              <Flex flexDirection="column" gap="l">
                <Flex gap="m" alignItems="center">
                  <AtiraIcon color="main" icon={faCheckSquare} size="xl" />

                  <Text fontSize="xm">{t('pricing.add_ons.feature.1')}</Text>
                </Flex>

                <Flex gap="m" alignItems="center">
                  <AtiraIcon color="main" icon={faCheckSquare} size="xl" />

                  <Text fontSize="xm">{t('pricing.add_ons.feature.2')}</Text>
                </Flex>

                <Flex gap="m" alignItems="center">
                  <AtiraIcon color="main" icon={faCheckSquare} size="xl" />

                  <Text fontSize="xm">{t('pricing.add_ons.feature.3')}</Text>
                </Flex>

                <Flex gap="m" alignItems="center">
                  <AtiraIcon color="main" icon={faCheckSquare} size="xl" />

                  <Text fontSize="xm">{t('pricing.add_ons.feature.4')}</Text>
                </Flex>

                <Flex gap="m" alignItems="center">
                  <AtiraIcon color="main" icon={faCheckSquare} size="xl" />

                  <Text fontSize="xm">{t('pricing.add_ons.feature.5')}</Text>
                </Flex>
              </Flex>
            </Flex>
          </AtiraColumn>

          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <Flex flexDirection="column">
              <AtiraImage src={Images.PricingAddOnsImage} />

              <SectionCallToAction to={''}>
                {t('pricing.section_c2a')}
              </SectionCallToAction>
            </Flex>
          </AtiraColumn>
        </Row>
      </AddOnsSection>
    </Container>
  );
};
