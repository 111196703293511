import { truncate } from 'lodash';

import i18n from '../i18n';

export const pluralize = (count: number, noun: string, suffix = 's') => {
  const lang = i18n.language;
  if (lang === 'en') {
    return `${noun}${count !== 1 ? suffix : ''}`;
  }
  return noun;
};

export const truncateString = (value: string, length?: number) => {
  const maxLength = length || 20;

  if (value && value.length >= maxLength) {
    return truncate(value, { length: maxLength });
  }

  return value;
};

export const Regexes = {
  EMAIL_REGEX: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  NAME_REGEX:
    /^(?![-._\s])[a-zA-Z0-9çğışöüİĞÇIŞÖÜ\u0600-\u06FF][-._a-zA-Z0-9çğışöüİĞÇIŞÖÜ\u0600-\u06FF\s]{1,18}(?<![-._\s])$/,
  URL_REGEX:
    /^((([a-zA-Z0-9-]{1,256}\.[a-zA-Z0-9-]{2,6})|(([0-9]{1,3}\.){3}[0-9]{1,3}))(:[0-9]{1,5})?)(\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]*)?$/,
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
