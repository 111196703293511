import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import i18n, { AppLangs } from '../../../i18n';
import { KanbanColumn as KanbanColumnModel } from '../../../model/kanban/KanbanColumn';
import { UpdateKanbanColumnDto } from '../../../model/kanban/dto/UpdateKanbanColumnDto';
import { Lengths } from '../../../model/shared/enum/Lengths.enum';
import { kanbanSliceSelectors } from '../../../redux/kanban/kanban.selector';
import { kanbanActions } from '../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { useKanbanContext } from '../kanban-context';
import { KanbanCard } from './KanbanCard';

enum ColumnsNames {
  CLOSED_WIN = 'Closed-win',
  CLOSED_LOST = 'Closed-lost',
  CLOSED_TO_COMPETITION = 'Closed-to-competition',
}

const getColumnHeaderBorderColor = (
  columnName: string,
  theme: DefaultTheme,
) => {
  switch (columnName) {
    case ColumnsNames.CLOSED_WIN:
      return theme.green;
    case ColumnsNames.CLOSED_LOST:
    case ColumnsNames.CLOSED_TO_COMPETITION:
      return theme.red;
    default:
      return theme.main;
  }
};

const StyledKanbanColumn = styled(Flex)`
  flex-direction: column;
  gap: 0.75rem;
  width: 18rem;
  height: 98%;
  margin-right: ${i18n.language === AppLangs.AR ? '0' : Spacing.m};
  margin-left: ${i18n.language === AppLangs.AR ? Spacing.m : '0'};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    height: 88%;
  }
`;

const ColumnHeader = styled(Flex)<{ columnName: string }>`
  border: 2px solid
    ${({ columnName, theme }) => getColumnHeaderBorderColor(columnName, theme)};
  flex-direction: column;
  gap: 0.1rem;
  padding: ${Spacing.s};
  border-radius: ${Rounded.xl};
  background-color: ${(props) => props.theme.white};
`;

const KanbanCardsWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  padding: ${Spacing.s} ${Spacing.s} 0 ${Spacing.s};
  background-color: ${(props) => props.theme.lightgray};
  border: 2px solid ${(props) => props.theme.lightgray};
  border-radius: ${Rounded.xl};
  position: relative;
  overflow: hidden;
`;

const KanbanCradsInnerWrapper = styled(Flex)<{ canAddCard: boolean }>`
  height: ${({ canAddCard }) => (canAddCard ? 'calc(100% - 3rem)' : '100%')};
  flex-direction: column;
  gap: ${Spacing.s};
  overflow-y: scroll;
  border-radius: ${Rounded.xl} ${Rounded.xl} ${Rounded.none} ${Rounded.none};

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const AddKanbanCardButton = styled(Button)`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3rem;
  border-radius: ${Rounded.none};
  margin: 0;
  background-color: ${(props) => props.theme.lightgray};
  text-align: start;
  display: flex;
  align-items: center;
  gap: ${Spacing.m};
`;

const ColumnHeaderTitleInput = styled(Input)`
  font-size: 1.2rem;
  font-weight: bold;
  border: ${({ readOnly, theme }) =>
    readOnly ? '0' : `1px solid ${theme.main}`} !important;
  transition: all 0.2s;
  padding: ${({ readOnly }) => (readOnly ? '0' : Spacing.s)} !important;
`;

const ColumnNameEditButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
`;

const CancelRenameColumnButton = styled(ColumnNameEditButton)``;

type KanbanColumnProps = {
  column: KanbanColumnModel;
};

export const KanbanColumn: React.FC<KanbanColumnProps> = ({ column }) => {
  const [canUpdateColName, setCanUpdateColName] = useState(false);
  const [updateColumnNameLoading, setUpdateColumnNameLoading] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const defaultKanban = useAppSelector(
    kanbanSliceSelectors.selectUserDefaultKanban,
  );

  const { control, getValues, handleSubmit, setValue } =
    useForm<UpdateKanbanColumnDto>({
      defaultValues: {
        name: column.name,
        userId,
        columnId: column._id,
        kanbanId: defaultKanban?._id,
      },
    });

  const { setCreateDrawerVisible } = useKanbanContext();

  const dispatch = useAppDispatch();

  const canAddCard = column.default !== true;

  const totalAmount = column.cards
    ?.map((card) => Number(card.amount))
    .reduce((total, acc) => total + acc, 0);

  const onCancelEditName = () => {
    setCanUpdateColName(false);
    setValue('name', column.name);
    if (ref.current) {
      ref.current.blur();
    }
  };

  const onStartEditName = () => {
    setCanUpdateColName(true);
    if (ref.current) {
      ref.current.focus();
    }
  };

  const onEditColumnName = async () => {
    try {
      setCanUpdateColName(false);

      const dto = getValues();

      if (dto.name !== column.name) {
        setUpdateColumnNameLoading(true);
        await dispatch(kanbanActions.updateKanbanColumnById(dto)).unwrap();
        AtiraToast.success(t('kanban.column.title.update.onsucess'));
      }
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setUpdateColumnNameLoading(false);
    }
  };

  return (
    <StyledKanbanColumn>
      <ColumnHeader columnName={column.name}>
        <Flex alignItems="center" width={'100%'} gap="s">
          <Controller
            control={control}
            name="name"
            rules={{ required: true, minLength: 3, maxLength: Lengths.NAME }}
            render={({ field: { value, onChange } }) => (
              <ColumnHeaderTitleInput
                readOnly={!canUpdateColName}
                value={value}
                onChange={onChange}
                maxLength={Lengths.NAME}
                ref={ref}
              />
            )}
          />

          <Flex alignItems="center" gap="s">
            {canUpdateColName ? (
              <CancelRenameColumnButton
                icon={faXmark}
                iconWidth="1x"
                onClick={onCancelEditName}
              />
            ) : null}

            {canUpdateColName ? (
              <ColumnNameEditButton
                icon={faSave}
                iconWidth="1x"
                onClick={handleSubmit(onEditColumnName)}
                loading={updateColumnNameLoading}
              />
            ) : null}

            {!canUpdateColName ? (
              <ColumnNameEditButton
                icon={faPencil}
                iconWidth="1x"
                onClick={onStartEditName}
                loading={updateColumnNameLoading}
              />
            ) : null}
          </Flex>
        </Flex>

        <Flex justifyContent="space-between" alignItems="center" width={'100%'}>
          <Text align="center">
            {column.cards?.length} {t('common.deals')}
          </Text>
          <Text>{totalAmount}$</Text>
        </Flex>
      </ColumnHeader>

      <KanbanCardsWrapper height={'100%'}>
        <KanbanCradsInnerWrapper canAddCard={canAddCard}>
          {column.cards?.map((c) => (
            <KanbanCard card={c} column={column} key={c._id} />
          ))}
        </KanbanCradsInnerWrapper>

        {canAddCard ? (
          <AddKanbanCardButton onClick={() => setCreateDrawerVisible(true)}>
            <AtiraIcon icon={faPlus} color="darkerSub" size="2x" />
            <Text color="darkerSub" fontSize="m">
              {t('common.add')}
            </Text>
          </AddKanbanCardButton>
        ) : null}
      </KanbanCardsWrapper>
    </StyledKanbanColumn>
  );
};
