import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import { Spacing } from '../theme/Spacing';

const StyledTabList = styled(TabList)<{ align?: string }>`
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  gap: ${Spacing.xxxl};
  flex-wrap: wrap;
  align-self: ${(props) => props.align || 'center'};
`;

enum AtiraTabsAlignment {
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end',
}

interface AtiraTabsProps {
  tabs: { tabTitle: string; tabPanel: React.ReactNode }[];
  align?: keyof typeof AtiraTabsAlignment;
  onChange?: (index: number) => void;
}

export const AtiraTabs: React.FC<AtiraTabsProps> = ({
  tabs,
  align = 'CENTER',
  onChange,
}) => {
  const location = useLocation();
  const { tabIndex } = location.state || {};
  const { theme } = useTheme();

  const [activeTab, setActiveTab] = useState(tabIndex || 0);

  return (
    <Tabs
      style={{
        justifyContent: 'start',
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
      }}
      selectedIndex={activeTab}
    >
      <StyledTabList align={AtiraTabsAlignment[align]}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.tabTitle}
            onClick={() => setActiveTab(index)}
            style={{
              color: activeTab === index ? theme.white : theme.black,
              borderRadius: '0.8rem',
              padding: `0.4rem ${Spacing.xxl}`,
              backgroundColor: activeTab === index ? theme.main : theme.white,
              cursor: 'pointer',
              fontWeight: '620',
              fontSize: '1.4rem',
              outline: 'none',
              marginBottom: '2rem',
            }}
          >
            {tab.tabTitle}
          </Tab>
        ))}
      </StyledTabList>

      {tabs.map((tab, index) => (
        <TabPanel
          key={tab.tabTitle}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {tab.tabPanel}
        </TabPanel>
      ))}
    </Tabs>
  );
};
