import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { AtiraTable } from '../../../../components/AtiraTable';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { AtriaTourClasses } from '../../../../components/tour/AtiraTourClasses.enum';
import i18n, { AppLangs } from '../../../../i18n';
import { WidgetKind } from '../../../../model/widget/types/WidgetKid.enum';
import { entrySliceSelectors } from '../../../../redux/entry/entry.selector';
import { entryActions } from '../../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { widgetSliceSelectors } from '../../../../redux/widget/widget.selector';
import { Rounded } from '../../../../theme/Rounded';
import { Shadow } from '../../../../theme/Shadow';
import { Spacing } from '../../../../theme/Spacing';
import { createEntriesWidgetTableColumns } from '../../../entries/utils/createEntriesWidgetTableColumns';

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: ${Spacing.s};
`;

const Title = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  padding: 0.2rem 0;
`;

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  overflow-y: scroll;
  width: 100%;
  height: 14rem;
  padding: 0;
  scrollbar-width: none;
  box-shadow: ${Shadow.SM};
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  margin: 0;
  bottom: 0;
  right: 0;
`;

const StyledTableWrapper = styled.div`
  .ant-table-wrapper {
    overflow-y: visible !important;
  }

  .ant-table-content {
    overflow: visible !important;
  }

  .ant-table-wrapper .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    border-bottom: 0.1rem solid black;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td:not(:last-child) {
    border-right: 0.01rem solid lightgrey;
  }

  .ant-table-tbody > tr > td.lead-cell {
    background-color: ${({ theme }) => theme.lightAccent} !important;
  }

  .ant-table-tbody > tr > td.contact-cell {
    background-color: ${({ theme }) => theme.lightMain} !important;
  }

  .ant-table-tbody > tr > td.junk-cell {
    background-color: ${({ theme }) => theme.darkSub} !important;
  }
`;

export const WidgetLatestEntries: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const entries = useAppSelector(entrySliceSelectors.selectLatestEntries);
  const entriesLoading = useAppSelector(
    entrySliceSelectors.selectLatestEntriesLoading,
  );
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  );
  const widget = useAppSelector(widgetSliceSelectors.selectUserWidgets)?.find(
    (w) => w.kind === WidgetKind.LAST_ENTRIES,
  );

  const tableColumns = useMemo(
    () => createEntriesWidgetTableColumns(tableSettings?.columns || [], { t }),
    [tableSettings?.columns, t],
  );

  const arrowIcon = i18n.language === AppLangs.AR ? faArrowLeft : faArrowRight;

  useEffect(() => {
    dispatch(
      entryActions.getLatestEntries({
        userId,
        meta: { count: 10, page: 0 },
      }),
    );
    dispatch(entryActions.getUserTableSettings({ userId }));
  }, [userId, dispatch]);

  return (
    <Wrapper>
      <Flex alignItems="center" justifyContent="space-between">
        <Title fontSize="l">
          {t(`dashboard.widget.title.${widget?.name}`)}
        </Title>
        <StyledButton>
          <Link to={'/entries'}>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={arrowIcon} color="main" />
              <Text color="main">{t('dashboard.widget.btn')}</Text>
            </Flex>
          </Link>
        </StyledButton>
      </Flex>

      <Container>
        <Flex
          flexDirection="column"
          width="100%"
          className={AtriaTourClasses.WIDGET_LATEST_ENTRIES}
        >
          <StyledTableWrapper>
            <AtiraTable
              size="small"
              columns={tableColumns}
              data={entries}
              loading={entriesLoading && !entries.length}
              rowSelection={undefined}
              pagination={false}
            />
          </StyledTableWrapper>
        </Flex>
      </Container>
    </Wrapper>
  );
};
