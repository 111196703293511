import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import merge from 'lodash/merge';
import React, { InputHTMLAttributes, forwardRef, useState } from 'react';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import i18n, { AppLangs } from '../i18n';
import { InputTypes } from '../model/form/types/InputTypes.enum';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { Text } from './Text';
import {
  LabelDirection,
  TitleAlignment,
} from './shared/types/SharedLabelProps';

const ShowHidePasswordButton = styled(Button)`
  position: absolute;
  top: 0.75rem;
  right: ${i18n.language === AppLangs.AR ? 'auto' : Spacing.m};
  left: ${i18n.language === AppLangs.AR ? Spacing.m : 'auto'};
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.main};
`;

const StyledInput = styled.input`
  width: 100%;
  transition: box-shadow 0.2s;
  outline: none !important;
  border-radius: ${Rounded.md};
  height: 2.3rem;
  font-family: inherit;
  direction: ${({ type }) =>
    i18n.language === AppLangs.AR &&
    (type === InputTypes.NUMBER.toLowerCase() ||
      type === InputTypes.EMAIL.toLowerCase())
      ? 'ltr'
      : 'auto'};
  &::placeholder {
    text-align: ${({ type }) =>
      i18n.language === AppLangs.AR &&
      (type === InputTypes.NUMBER.toLowerCase() ||
        type === InputTypes.EMAIL.toLowerCase())
        ? 'end'
        : 'auto'};
  }
`;

const StyledInputWrapper = styled(Flex)`
  gap: 0.2rem;
  align-items: center;
  border-radius: ${Rounded.md};
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  id?: string;
  valid?: boolean;
  width?: string;
  height?: string;
  border?: string;
  borderRadius?: keyof typeof Rounded;
  placeholder?: string;
  padding?: string;
  margin?: string;
  icon?: IconProp;
  errorMessage?: string;
  color?: string;
  titleAlign?: keyof typeof TitleAlignment;
  labelDirection?: keyof typeof LabelDirection;
  iconSize?: SizeProp;
}

enum PasswordInputTypes {
  TEXT = 'TEXT',
  PASSWORD = 'PASSWORD',
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { type, ...restProps } = props;

  const [inputType, setInputType] = useState(type);

  const { theme } = useTheme();

  const togglePasswordInputType = () => {
    setInputType(
      inputType === PasswordInputTypes.PASSWORD.toLowerCase()
        ? PasswordInputTypes.TEXT.toLowerCase()
        : PasswordInputTypes.PASSWORD.toLowerCase(),
    );
  };

  return (
    <Flex flexDirection="column" gap="s" width={props.width || '100%'}>
      <Flex
        width={'100%'}
        gap="s"
        flexDirection={
          props.labelDirection === LabelDirection.HORIZONTAL ? 'row' : 'column'
        }
      >
        {props.title ? (
          <label
            htmlFor={props.id}
            style={{
              color: theme.black,
              cursor: 'pointer',
              textAlign: props.titleAlign,
              width: 'fit-content',
              alignSelf: props.titleAlign || 'flex-start',
              flex: 1,
              margin:
                props.labelDirection === LabelDirection.HORIZONTAL
                  ? 'auto'
                  : '0',
            }}
          >
            {props.title}
            {props.required ? (
              <span style={{ color: theme.red }}> *</span>
            ) : null}
          </label>
        ) : null}

        {props.icon ? (
          <Flex flexDirection="column" position="relative" gap="s" flex={2}>
            <StyledInputWrapper
              opacity={props.disabled ? '0.2' : '1'}
              border={
                props.valid === false
                  ? '2px solid red'
                  : `1px solid ${theme.lightgray}`
              }
              borderRadius={props.borderRadius || 'md'}
              style={{ padding: `0 ${Spacing.s}` }}
            >
              <StyledInput
                ref={ref}
                type={inputType}
                style={merge(props.style, {
                  height: props.height,
                  border: '0',
                  borderRadius: props.borderRadius,
                  placeholder: props.placeholder,
                  margin: props.margin,
                })}
                {...restProps}
              />

              <AtiraIcon icon={props.icon} size={props.iconSize || 'lg'} />
            </StyledInputWrapper>

            {props.errorMessage && props.valid === false ? (
              <Text color="red" fontSize="s">
                {props.errorMessage}
              </Text>
            ) : null}
          </Flex>
        ) : (
          <Flex flexDirection="column" position="relative" gap="s" flex={2}>
            <StyledInput
              ref={ref}
              type={inputType}
              style={merge(
                props.style,
                props.disabled ? { opacity: '0.2' } : {},
                props.valid === false
                  ? { border: '2px solid red', boxShadow: 'unset' }
                  : {},
                {
                  width: '100%',
                  height: props.height,
                  border: props.border || `1px solid ${theme.lightgray}`,
                  borderRadius: props.borderRadius,
                  placeholder: props.placeholder,
                  padding: props.padding || `${Spacing.o} ${Spacing.s}`,
                  margin: props.margin,
                },
              )}
              {...restProps}
            />

            {type === PasswordInputTypes.PASSWORD.toLowerCase() ? (
              <ShowHidePasswordButton
                onClick={togglePasswordInputType}
                icon={
                  inputType === PasswordInputTypes.PASSWORD.toLowerCase()
                    ? faEye
                    : faEyeSlash
                }
                backgroundColor="transparent"
              />
            ) : null}

            {props.errorMessage && props.valid === false ? (
              <Text color="red" fontSize="s">
                {props.errorMessage}
              </Text>
            ) : null}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
});
