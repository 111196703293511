import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab-btn {
    padding: 0.2rem;
    color: ${(props) => props.theme.black} !important;
  }
`;

type NotificationsDrawerProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const NotificationsDrawer: React.FC<NotificationsDrawerProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      styles={{ body: { padding: 0 } }}
      title={t('header.bell.drawer.title')}
      onClose={onClose}
      open={open}
      width={'25rem'}
    >
      <hr />
      <StyledTabs size="large" defaultActiveKey="1" centered tabBarGutter={80}>
        <Tabs.TabPane tab={t('header.bell.drawer.products_updates')} key="1">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="m"
            padding="m"
          >
            <Text>{t('header.bell.drawer.articles_news.description')}</Text>
            <AtiraImage src={Images.AtiraSpaceLogoBetaMain} />
          </Flex>
        </Tabs.TabPane>

        <Tabs.TabPane tab={t('header.bell.drawer.articles_news')} key="2">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="m"
            padding="m"
          >
            <Text>{t('header.bell.drawer.products_updates.description')}</Text>

            <AtiraImage src={Images.AtiraSpaceLogoBetaMain} />
          </Flex>
        </Tabs.TabPane>
      </StyledTabs>
    </Drawer>
  );
};
