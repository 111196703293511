import { Outlet } from 'react-router-dom';

import { Flex } from '../components/Flex';
import usePageTracking from '../hooks/usePageTracking';
import { PublicFooter } from './PublicFooter';
import { PublicHeader } from './PublicHeader';

export const PublicLayout: React.FC = () => {
  usePageTracking();

  return (
    <Flex flexDirection="column" flex={1}>
      <PublicHeader />
      <Outlet />
      <PublicFooter />
    </Flex>
  );
};
