import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Tooltip } from 'antd';
import { TFunction } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Textarea } from '../../../components/Textarea';
import { Entry } from '../../../model/entry/Entry';
import { UpdateEntryDto } from '../../../model/entry/dto/UpdateEntryDto';
import { CreateFormDtoInputNames } from '../../../model/form/types/CreateFormDtoInputNames.enum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { comparePayloads } from '../../../utils/ComparePayloads';
import { Regexes } from '../../../utils/String';

const StyledInput = styled(Input)`
  height: 2.5rem;
  border: 2px solid ${(props) => props.theme.main};
`;

const StyledTextarea = styled(Textarea)`
  border: 2px solid ${(props) => props.theme.main};
  max-height: 15rem;
`;

const CustomFieldButton = styled(Button)`
  margin: 0;
  height: auto;
  width: 2.4rem;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
`;

const UpdateButton = styled(Button)`
  width: 7rem;
  height: 2.5rem;
  font-size: 1.3rem;
  margin: 0;
`;

const validateNameAndEmail = (
  value: string,
  inputName: CreateFormDtoInputNames,
  t: TFunction,
) => {
  if (
    inputName === CreateFormDtoInputNames.NAME &&
    value &&
    (!value.trim() || value.trim().length < 3)
  ) {
    return t('entries.update.error.short_name');
  }
  if (
    inputName === CreateFormDtoInputNames.EMAIL &&
    value &&
    (!value.trim() || !Regexes.EMAIL_REGEX.test(value.trim()))
  ) {
    return t('entries.update.error.email_validate');
  }
  return true;
};

interface EntryUpdateDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  entry: Entry | null;
}

export const EntryUpdateDrawer: React.FC<EntryUpdateDrawerProps> = ({
  isOpen,
  onClose,
  entry,
}) => {
  const [entryUpdateLodaing, setEntryUpdateLoading] = useState(false);

  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const dispatch = useAppDispatch();

  const { control, getValues, reset, handleSubmit } = useForm<UpdateEntryDto>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customFields',
  });

  const onUpdateEntry = async () => {
    try {
      setEntryUpdateLoading(true);

      const dto = getValues();

      const { entryId, userId, ...modifiableDto } = dto;

      const updatedData = comparePayloads(
        entry!,
        modifiableDto,
      ) as UpdateEntryDto;

      const hasCustomFieldsChanged = !isEqual(
        entry?.customFields,
        dto.customFields,
      );

      if (!isEmpty(updatedData)) {
        await dispatch(
          entryActions.updateEntryById({
            ...updatedData,
            userId,
            entryId: entry?._id!,
            ...(hasCustomFieldsChanged
              ? { customFields: [...dto.customFields!] }
              : {}),
          }),
        ).unwrap();
        await dispatch(
          entryActions.getEntryById({ entryId: entry?._id!, userId: userId }),
        ).unwrap();
      }

      onClose();
      AtiraToast.success(t('entries.update.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setEntryUpdateLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && entry) {
      reset({
        description: entry?.description || '',
        fields: entry?.fields || {},
        customFields: entry?.customFields || [],
        entryId: entry._id || '',
        userId,
      });
    }
  }, [userId, entry, isOpen, reset]);

  return (
    <Drawer
      title={`${t('entries.modal.update.title')} - ${entry?.fields.name || entry?.fields.email}`}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flexDirection="column" justifyContent="center" gap="m">
        {entry?.parentForm?.inputs?.map((input) => {
          return (
            <Controller
              key={input._id}
              name={`fields.${input.name}`}
              control={control}
              rules={{
                required: input.required,
                validate: (value: string) =>
                  validateNameAndEmail(value, input.name, t),
              }}
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => (
                <StyledInput
                  value={value}
                  onChange={onChange}
                  title={t(`common.${input.name}`)}
                  id={input._id}
                  type={input.type}
                  required={input.required}
                  valid={!(errors.fields?.[input.name] as FieldError)?.message}
                  errorMessage={
                    (errors.fields?.[input.name] as FieldError)?.message
                  }
                  disabled={
                    input.name === CreateFormDtoInputNames.EMAIL.toLowerCase()
                  }
                />
              )}
            />
          );
        })}
        <Controller
          name={'description'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Flex flexDirection="column" gap="s">
              <StyledTextarea
                value={value}
                onChange={onChange}
                id={'description'}
                rows={3}
                title={t('common.description')}
              />
            </Flex>
          )}
        />

        <Flex width={'100%'} flexDirection="column" gap="s">
          <Flex marginBottom="s" marginTop="s" flexDirection="column">
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontWeight={600}>{t('common.custom_fields')}</Text>

              <Tooltip title={t('common.tooltip.add_new_field')}>
                <CustomFieldButton
                  icon={faPlus}
                  iconWidth="2x"
                  disabled={fields.length >= 3}
                  onClick={() => append({ label: '', value: '' })}
                />
              </Tooltip>
            </Flex>

            <Text wordBreak="break-word" color="gray" fontSize="s">
              {t('common.custom_fields.note')}
            </Text>
          </Flex>

          <Flex gap="m" flexDirection="column" width={'100%'}>
            {fields.map((field, index) => (
              <Flex flexDirection="column" gap="s" key={field.id}>
                <Controller
                  control={control}
                  name={`customFields.${index}.label`}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      border="0"
                      placeholder={t('common.input.edit_label')}
                      maxLength={12}
                      padding="initial 0"
                    />
                  )}
                />

                <Flex justifyContent="space-between" gap="s">
                  <Controller
                    control={control}
                    name={`customFields.${index}.value`}
                    render={({ field: { value, onChange } }) => (
                      <StyledInput
                        value={value}
                        onChange={onChange}
                        placeholder={t('common.input.edit_value')}
                        maxLength={280}
                      />
                    )}
                  />

                  <Tooltip title={t('common.tooltip.remove_this_field')}>
                    <CustomFieldButton
                      icon={faMinus}
                      iconWidth="2x"
                      onClick={() => remove(index)}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>

        <UpdateButton
          onClick={handleSubmit(onUpdateEntry)}
          loading={entryUpdateLodaing}
          title={t('common.update')}
        />
      </Flex>
    </Drawer>
  );
};
