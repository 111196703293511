import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { CreateEntryDto } from '../../../model/entry/dto/CreateNewEntryDto';
import { FormInput } from '../../../model/form/FormInput';
import { entryActions } from '../../../redux/entry/entry.slice';
import { formSliceSelectors } from '../../../redux/forms/form.selector';
import { formActions } from '../../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

const SaveButton = styled(Button)`
  width: 8rem;
  height: 3.5rem;
  margin: auto;
  font-size: 1.3rem;
`;

type EntryCreateModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  showSelect?: boolean;
};

export const EntryCreateModal: React.FC<EntryCreateModalProps> = ({
  isOpen,
  onClose,
  showSelect = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const forms = useAppSelector(formSliceSelectors.selectMyForms);
  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);

  const [createEntryLoading, setCreateEntryLoading] = useState(false);

  const { control, getValues, handleSubmit, reset } = useForm<CreateEntryDto>({
    defaultValues: {
      ...currentForm?.inputs?.reduce(
        (name, input: Partial<FormInput>) => {
          name[input.name!] = '';
          return name;
        },
        {} as Record<string, string>,
      ),
      formId: currentForm?._id,
      ownerId: userId,
      orphan: currentForm?.orphan,
    },
  });

  const onFormSelectChange = (formId: string) => {
    const _form = forms.find((f) => f._id === formId)!;

    if (_form) {
      dispatch(formActions.setCurrentForm(_form));
    }
  };

  const onEntrySubmit = async () => {
    try {
      setCreateEntryLoading(true);

      const dto = getValues();

      await dispatch(
        entryActions.createNewEntry({
          ...dto,
          formId: currentForm?._id!,
          orphan: currentForm?.orphan === true,
        }),
      ).unwrap();

      await dispatch(
        entryActions.getLatestEntries({ userId, meta: { count: 10, page: 0 } }),
      ).unwrap();
      await dispatch(formActions.getMyForms({ userId })).unwrap();

      onClose();
      reset();

      AtiraToast.success(t('entries.entry.create.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setCreateEntryLoading(false);
    }
  };

  useEffect(() => {
    reset({
      ...currentForm?.inputs?.reduce(
        (name, input: Partial<FormInput>) => {
          name[input.name!] = '';
          return name;
        },
        {} as Record<string, string>,
      ),
      formId: currentForm?._id,
      ownerId: userId,
      orphan: currentForm?.orphan,
    });
  }, [
    currentForm?._id,
    currentForm?.inputs,
    currentForm?.orphan,
    reset,
    userId,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('entries.new_entry')}
      content={{ maxHeight: '80vh' }}
      key={currentForm?._id}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        gap="m"
        width={'30rem'}
      >
        {currentForm?.orphan ? (
          <Text color="gray" align="center">
            {t('entries.create.entry_modal.without_form_note')}
          </Text>
        ) : null}

        {currentForm?.title && currentForm.orphan !== true ? (
          <Text align="center" color="gray">
            {t('entries.create_entry.modal.form_title')}
            <strong style={{ padding: '0 0.3rem' }}>{currentForm.title}</strong>
          </Text>
        ) : null}

        {showSelect ? (
          <Flex
            width="22rem"
            alignItems="center"
            flexDirection="column"
            gap="s"
            style={{ margin: 'auto' }}
          >
            <Select
              defaultValue={currentForm?.title}
              placeholder={t('common.dropdown.select')}
              options={forms.map((f) => ({ value: f._id, label: f.title }))}
              onChange={onFormSelectChange}
              style={{ height: '3rem', width: '100%' }}
            />
          </Flex>
        ) : null}

        {currentForm?.inputs?.length ? (
          <Flex
            flexDirection="column"
            width={'100%'}
            alignItems="center"
            gap="m"
          >
            {currentForm.inputs?.map((input: Partial<FormInput>) => (
              <Controller
                name={input.name!}
                control={control}
                rules={{
                  required: {
                    value: input.required === true,
                    message: t('common.this_field_required'),
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    type={input.type}
                    title={t(`common.${input.name}`)}
                    name={input.name}
                    value={value}
                    onChange={onChange}
                    width="22rem"
                    height="2.5rem"
                    padding="0 0.5rem"
                    valid={!Boolean(error?.message?.length)}
                    errorMessage={error?.message || ''}
                  />
                )}
                key={input._id}
              />
            ))}
          </Flex>
        ) : null}
        <SaveButton
          onClick={handleSubmit(onEntrySubmit)}
          loading={createEntryLoading}
        >
          {t('common.save')}
        </SaveButton>
      </Flex>
    </Modal>
  );
};
