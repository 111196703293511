import { AppLangs } from './i18n';

export async function setLangugeAndFont() {
  const storedLang = (window.localStorage.getItem('atiraspace_lang') ||
    AppLangs.EN) as AppLangs;
  const direction = storedLang === AppLangs.AR ? 'rtl' : 'ltr';

  const doc = document.documentElement;

  doc.setAttribute('dir', direction);
  doc.setAttribute('lang', storedLang);

  switch (storedLang) {
    case AppLangs.EN: {
      doc.classList.remove('atira-arabic-font');
      doc.classList.add('atira-english-font');
      break;
    }

    case AppLangs.AR: {
      doc.classList.remove('atira-english-font');
      doc.classList.add('atira-arabic-font');
      break;
    }
  }
}
