import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { KanbanCard } from '../../../model/kanban/KanbanCard';
import { kanbanSliceSelectors } from '../../../redux/kanban/kanban.selector';
import { useAppSelector } from '../../../redux/store';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';
import { useKanbanContext } from '../kanban-context';

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  border-radius: 0.3rem;
  width: 7rem;
`;

interface KanbanCardReadDrawerProps {
  kanbanCard: KanbanCard | undefined;
  isOpen: boolean;
  onClose: VoidFunction;
}

export const KanbanCardReadDrawer: React.FC<KanbanCardReadDrawerProps> = ({
  kanbanCard,
  isOpen = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const { setUpdateDrawerVisible } = useKanbanContext();

  const defaultKanban = useAppSelector(
    kanbanSliceSelectors.selectUserDefaultKanban,
  );

  const parentColumn = defaultKanban?.columns?.find(
    (col) => col._id === kanbanCard?.parentId,
  );

  if (!kanbanCard) {
    return null;
  }

  return (
    <Drawer
      title={t('deals.darwer.preview.title')}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flex={1} flexDirection="column" gap="m">
        <StyledFlex>
          <BoldText>{t('common.id')}:</BoldText>

          <ValueText>{kanbanCard?._id}</ValueText>
        </StyledFlex>

        <StyledFlex>
          <BoldText>{t('deals.drawer.input.deal_name')}: </BoldText>

          <ValueText>{kanbanCard?.title}</ValueText>
        </StyledFlex>

        <StyledFlex>
          <BoldText>{t('deals.drawer.input.contact_name')}: </BoldText>
          <ValueText>
            {kanbanCard?.entry?.fields.name || kanbanCard?.entry?.fields.email}
          </ValueText>
        </StyledFlex>

        <StyledFlex>
          <BoldText>{t('common.stage')}: </BoldText>
          <ValueText>{parentColumn?.name}</ValueText>
        </StyledFlex>

        <StyledFlex>
          <BoldText>{t('common.amount')}:</BoldText>

          <ValueText>{kanbanCard?.amount}$</ValueText>
        </StyledFlex>

        {kanbanCard?.closingDate ? (
          <StyledFlex>
            <BoldText>{t('deals.drawer.input.closing_date')}: </BoldText>

            <ValueText>{standardDate(kanbanCard.closingDate)}</ValueText>
          </StyledFlex>
        ) : null}

        {kanbanCard?.note ? (
          <StyledFlex>
            <BoldText>{t('common.description')}:</BoldText>

            <Text wordBreak="break-word">{kanbanCard.note}</Text>
          </StyledFlex>
        ) : null}

        {kanbanCard?.customFields?.map((field, index) => (
          <StyledFlex key={`${field.label}-${field.value}-${index}`}>
            <BoldText>{field.label}:</BoldText>

            <ValueText>{field.value}</ValueText>
          </StyledFlex>
        ))}

        {kanbanCard?.movingReason ? (
          <Flex gap="s" flexWrap="wrap">
            <BoldText>{t('common.moving_reason')}:</BoldText>
            <Flex>
              <Text wordBreak="break-word">{kanbanCard.movingReason}</Text>
            </Flex>
          </Flex>
        ) : null}

        <StyledButton
          title={t('common.edit')}
          onClick={() => {
            onClose();
            setUpdateDrawerVisible(true);
          }}
        />
      </Flex>
    </Drawer>
  );
};
